body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* utils */

.bg-dark-blue {
    background-color: #0a122c;
}

.bg-faded-blue {
    background-color: #0c233d;
}

.bg-faded-black {
    background-color: #070808;
}

.ml-25px {
    margin-left: 25px;
}

.h-40px {
    height: 40px;
}

.text-light-blue {
    color: #41b5a7;
}

.fs-30px {
    font-size: 30px;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-65 {
    width: 65%;
}

.h-10 {
    height: 10%;
}

.h-15 {
    height: 15%;
}

.h-30 {
    height: 30%;
}

.h-40 {
    height: 40%;
}

.h-60 {
    height: 60%;
}

.h-65 {
    height: 65%;
}

.h-80 {
    height: 80%;
}

.h-85 {
    height: 85%;
}

.h-90 {
    height: 90%;
}