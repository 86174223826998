.frame {
  display: flex;
  flex-direction: column;
  font-size: 0.8vw;
  font-weight: bold;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .t-yellow {
    color: #ffc529;
  }

  .top-bar {
    display: flex;
    justify-content: space-between;

    .tag {
      align-self: flex-end;
      width: 70%;
      background: #182c53;
      padding: 0.7rem;
      color: #fff;
    }

    .cards-container {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px 5px 0 0;

      .card {
        transition: transform 0.2s;
        margin: 2px auto;
        background: #d1d3d4;
        width: 30px;
        height: 42px;
      }
    }

    .cards-container:hover {
      div:nth-child(1):hover {
        transform: scale(2.5) translate(-9px, -12px);
        z-index: 2;
      }

      div:nth-child(2):hover {
        transform: scale(2.5) translate(9px, -12px);
        z-index: 2;
      }
    }
  }

  .video-container {
    flex: 1;
    background: #000;
    overflow: hidden;
  }

  .bottom-bar {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    background: #383838;
  }

  .pointer {
    cursor: pointer;
  }

  .not-allowed {
    cursor: not-allowed;
  }
}
