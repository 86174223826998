.nav-height {
  height: 75px;
}

.exit {
  background: #cf5060;
  border-radius: 0.25vw;
  cursor: pointer;
  width: 2.5vw;
  height: 2.5vw;

  img {
    width: 1.2vw;
    height: 1.2vw;
  }
}