.ludo-textfield {
  width: 100%;
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  overflow: hidden;
  padding: 1rem 2.5rem;
  margin-top: 2rem;

  input {
    font-size: 1vw;
    border: none;
    outline: none;
    flex: 1;
    padding: 0.6rem 1rem;
  }

  .icon {
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}