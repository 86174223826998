* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$frameWidth: 200px;
$frameHeight: 180px;

.unity-container {
  border: 1px solid #41b5a7;
  outline: 1px solid brown;
  height: 100%;
  width: 100%;
  background-color: #0a122c;
  overflow: hidden;

  .scene {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    &.loaded {
      display: block;
    }

    .frame {
      width: $frameWidth;
      height: $frameHeight;
      z-index: 2;
      position: absolute;

      .placeholder {
        background: #000;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 60%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &.pov-1 {
      .frame-1 {
        bottom: 0.5%;
        left: 50%;
        transform: translateX(-50%);
      }

      .frame-2 {
        bottom: 10%;
        right: 7%;
      }

      .frame-3 {
        bottom: 48%;
        right: 13%;
      }

      .frame-4 {
        bottom: 48%;
        left: 13%;
      }

      .frame-5 {
        bottom: 7%;
        left: 12%;
        transform: translateX(-50%);
      }
    }

    &.pov-2 {
      .frame-1 {
        bottom: 20%;
        left: 5%;
      }

      .frame-2 {
        bottom: 0.5%;
        left: 50%;
        transform: translateX(-50%);
      }

      .frame-3 {
        bottom: 6%;
        right: 10%;
      }

      .frame-4 {
        bottom: 60%;
        right: 40%;
      }

      .frame-5 {
        bottom: 56%;
        left: 15%;
      }
    }

    &.pov-3 {
      .frame-1 {
        bottom: 50%;
        left: 18%;
      }

      .frame-2 {
        bottom: 6%;
        left: 18%;
      }

      .frame-3 {
        bottom: 1%;
        right: 20%;
        transform: translateX(-50%);
      }

      .frame-4 {
        bottom: 53%;
        right: 18%;
      }

      .frame-5 {
        bottom: 60%;
        left: 42%;
      }
    }

    &.pov-4 {
      .frame-1 {
        bottom: 50%;
        right: 18%;
      }

      .frame-2 {
        bottom: 58%;
        right: 40%;
      }

      .frame-3 {
        bottom: 54%;
        left: 20%;
      }

      .frame-4 {
        bottom: 1%;
        left: 40%;
        transform: translateX(-50%);
      }

      .frame-5 {
        bottom: 8%;
        right: 16%;
      }
    }

    &.pov-5 {
      .frame-1 {
        bottom: 20%;
        right: 7%;
      }

      .frame-2 {
        bottom: 56%;
        right: 18%;
      }

      .frame-3 {
        bottom: 60%;
        right: 48%;
      }

      .frame-4 {
        bottom: 6%;
        left: 8%;
      }

      .frame-5 {
        bottom: 1%;
        left: 54%;
        transform: translateX(-50%);
      }
    }
  }
}

button {
  margin: 1rem;
  padding: 0.5rem;
  display: block;
}
