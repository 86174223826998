.message-container {
  border-top: 1px solid #3f5088;
  font-size: 1.4rem;
}

.scroll-container {
  margin-right: 0;
  padding-right: 0;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border: 1px white solid;
  background: white 10px 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #5bc0de;
  border-radius: 10px;
}